import { useFolder } from '@/composables/use-folder';
import filesystemApiService from '@/services/filesystem-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { makeEmailInputDataStore } from '@/utils/helpers/email-input-helpers';
import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  ToastProgrammatic as Toast,
} from 'buefy';
import { computed, nextTick, onMounted, ref } from 'vue';
import HrbrDuplicateFolderModal from '../../Modals/HrbrDuplicateFolder.vue';
import HrbrEditFolderCollaboratorsModal from '../../Modals/HrbrEditFolderCollaborators.vue';
import { useFoldersMenuStore } from '../stores/folders-menu-store';

const EDIT_PERMISSION = 'permissions.filesystem.folder.edit';
const ORG_ADMIN = 'orgAdmin';
const SHARED_WITH_ME = 'SHAREDWITHME';
const CREATED_BY_ME = 'CREATEDBYME';

export function useFolderActions() {
  const harbourStore = useHarbourStore();
  const foldersMenuStore = useFoldersMenuStore();

  const isRenamingFolder = ref(false);
  const isCreatingFolder = ref(false);
  const isDeletingFolder = ref(false);
  const isDownloadingFolder = ref(false);

  const emailInputData = makeEmailInputDataStore();

  const isPermissionAvailable = (permission) => {
    const permissions = harbourStore.contextDict?.auth_permissions ?? [];
    return permissions.includes(permission);
  };

  const isRoleAvailable = (role) => {
    const roles = harbourStore.contextDict?.auth_roles ?? [];
    return roles.includes(role);
  };

  const isEditSharedPermissionAvailable = (origin, collaborators = []) => {
    const systemEmail = harbourStore.contextDict?.systememail;

    const isOrgAdmin = isRoleAvailable(ORG_ADMIN);
    const isSharedWithMe = origin === SHARED_WITH_ME;
    const isCollaborator = collaborators.includes(systemEmail);
    const canEdit = isOrgAdmin || isCollaborator;

    if (isSharedWithMe && !canEdit) return false;
    return true;
  };

  const loadCachedContacts = () => {
    const localDataString = localStorage.getItem('hrbr_emailInputData');
    if (!localDataString) return;
    const localData = JSON.parse(localDataString);
    Object.keys(localData).forEach((key) => (emailInputData[key] = localData[key]));
  };

  const refreshGridRows = (...folderIds) => {
    if (!foldersMenuStore.agGridApi) return;

    const updatedRows = [];
    for (const folderId of folderIds) {
      const rowNode = foldersMenuStore.agGridApi.getRowNode(folderId);
      if (rowNode) updatedRows.push(rowNode.data);
    }
    foldersMenuStore.agGridApi.applyTransaction({ update: updatedRows });
  };

  const expandNodesToTarget = (folderId) => {
    const rowNode = foldersMenuStore.agGridApi.getRowNode(folderId);
    if (!rowNode) return;
    let tempNode = rowNode;
    while (tempNode.parent && tempNode.level !== -1) {
      tempNode.setExpanded(true);
      tempNode = tempNode.parent;
    }
  };

  const handleEditFolderName = (folder, newFolderName) => {
    const folderId = folder.id;
    const folderName = folder.name;
    const folderOrigin = folder.origin;
    const collaborators = folder.collaborators;

    const isEditPermission = isPermissionAvailable(EDIT_PERMISSION);
    const isEditSharedPermission = isEditSharedPermissionAvailable(folderOrigin, collaborators);

    const specialFolders = ['#home', '#shared'];
    const containsSpecial = (folderId) => specialFolders.includes(folderId);

    if (!folderId) return;
    if (!isEditPermission) return;
    if (!isEditSharedPermission) return;
    if (containsSpecial(folderId)) return;
    if (folderName === newFolderName.trim()) return;

    if (!newFolderName.trim().length) {
      Toast.open({
        duration: 2500,
        message: `The name must have at least one character. Please try again.`,
        position: 'is-top',
        type: 'is-warning',
      });
      return;
    }

    updateFolderName({
      folderId,
      folderName: newFolderName,
    });
  };

  const updateFolderName = async ({ folderId, folderName }) => {
    const folder = harbourStore.myFolders.find((folder) => folder.id === folderId);
    const prevFolderName = folder.name;
    const newFolderName = folderName.trim();

    try {
      isRenamingFolder.value = true;

      harbourStore.setFolderName(folderId, newFolderName);
      await filesystemApiService.updateFolderName({
        folderName: newFolderName,
        folderId,
      });
    } catch (err) {
      harbourStore.setFolderName(folderId, prevFolderName);

      Toast.open({
        duration: 2500,
        message: `Something went wrong...`,
        position: 'is-top',
        type: 'is-danger',
      });
      console.error(err);
    } finally {
      isRenamingFolder.value = false;
    }
  };

  const handleAddFolderMain = (folderId, doneCallback = null) => {
    const getFolder = (folder) => folder.id === folderId;
    const mainFolder = harbourStore.myFolders.find(getFolder);
    if (mainFolder) handleAddFolder(mainFolder, doneCallback);
  };

  const handleAddFolder = (folder, doneCallback = null) => {
    const folderId = folder.id;
    const folderPath = folder.folderPath;
    const folderOrigin = folder.origin;
    const collaborators = folder.collaborators;

    const isEditPermission = isPermissionAvailable(EDIT_PERMISSION);
    const isEditSharedPermission = isEditSharedPermissionAvailable(folderOrigin, collaborators);

    const specialFolders = ['#shared'];
    const containsSpecial = (folderId) => specialFolders.includes(folderId);

    if (!folderId) return;
    if (!isEditPermission) return;
    if (!isEditSharedPermission) return;
    if (containsSpecial(folderId)) return;

    const maxFolderDepth = 8;
    if (folderPath.length >= maxFolderDepth) {
      Dialog.alert({
        title: 'Create new folder',
        message: 'The maximum folder depth level has been exceeded.',
        type: 'is-warning',
      });
      return;
    }

    promptAddFolder(folder, doneCallback);
  };

  const promptAddFolder = (folder, doneCallback) => {
    const folderId = folder.id;

    Dialog.prompt({
      title: 'New folder',
      inputAttrs: {
        value: '',
        placeholder: 'Folder name',
        maxlength: 300,
        minlength: 1,
      },
      onConfirm: (newFolderName) => {
        addFolder({
          folderName: newFolderName,
          folderId,
          doneCallback,
        });
      },
    });
  };

  const addFolder = async ({ folderName, folderId, doneCallback = null }) => {
    const newFolderName = folderName.trim();
    const prevCurrentFolder = harbourStore.currentFolder;

    try {
      isCreatingFolder.value = true;

      const respData = await filesystemApiService.createNewFolder({
        folderName: newFolderName,
        folderId,
      });

      const newFolderId = respData.newfolderid;
      const creatorName = harbourStore.contextDict.systememailname;
      const creatorEmail = harbourStore.contextDict.systememail;
      const { collaborators, viewers } = getParentFolderPermissions(folderId);

      const newFolderData = harbourStore.createFolderData({
        id: newFolderId,
        name: newFolderName,
        parent: folderId,
        origin: CREATED_BY_ME,
        creatorName,
        creatorEmail,
        viewers,
        collaborators,
      });

      const newFolder = useFolder(newFolderData);
      newFolder.setFolderPath(harbourStore.myFolders);

      harbourStore.setCurrentFolder(newFolderId);
      harbourStore.setFolders([...harbourStore.myFolders, newFolder]);

      refreshGridRows(prevCurrentFolder);
      nextTick(() => expandNodesToTarget(newFolderId));

      doneCallback && doneCallback(newFolder);
    } catch (err) {
      harbourStore.setCurrentFolder(prevCurrentFolder);
      harbourStore.getFolders();

      Toast.open({
        duration: 2500,
        message: `Something went wrong...`,
        position: 'is-top',
        type: 'is-danger',
      });
      console.error(err);
    } finally {
      isCreatingFolder.value = false;
    }
  };

  const getParentFolderPermissions = (parentFolderId) => {
    const creatorEmail = harbourStore.contextDict.systememail;
    const collaborators = [];
    const viewers = [];

    const parentFolder = harbourStore.myFolders.find((f) => f.id === parentFolderId);
    if (parentFolderId === '#home') {
      collaborators.push(creatorEmail);
    } else {
      collaborators.push(...parentFolder.collaborators);
      viewers.push(...parentFolder.viewers);
    }
    return { collaborators, viewers };
  };

  const handleDeleteFolder = (folder) => {
    const folderId = folder.id;
    const folderOrigin = folder.origin;
    const collaborators = folder.collaborators;

    const isEditPermission = isPermissionAvailable(EDIT_PERMISSION);
    const isEditSharedPermission = isEditSharedPermissionAvailable(folderOrigin, collaborators);

    const specialFolders = ['#home', '#shared'];
    const containsSpecial = (folderId) => specialFolders.includes(folderId);

    if (!folderId) return;
    if (!isEditPermission) return;
    if (!isEditSharedPermission) return;
    if (containsSpecial(folderId)) return;

    promptDeleteFolder(folder);
  };

  const promptDeleteFolder = (folder) => {
    const folderId = folder.id;
    const folderName = folder.name;
    const folderParent = folder.parent;
    const folderOrigin = folder.origin;

    Dialog.confirm({
      title: 'Delete folder',
      message: `Are you sure you want to delete this folder <b>${folderName}</b> including any assets and subfolders within?`,
      confirmText: 'Delete',
      type: 'is-danger',
      onConfirm: () => {
        deleteFolder({ folderId, folderName, folderParent, folderOrigin });
      },
    });
  };

  const getFolderIdsToDelete = (folderId) => {
    const folderIdsToRemove = [folderId];

    harbourStore.myFolders.forEach((folder) => {
      const folderLineage = [];
      let parentId = folder.parent;
      while (parentId) {
        folderLineage.push(parentId);
        const parentFolder = harbourStore.myFolders.find((f) => f.id === parentId);
        parentId = parentFolder.parent;
      }
      if (folderLineage.includes(folderId)) {
        folderIdsToRemove.push(folder.id);
      }
    });
    return folderIdsToRemove;
  };

  const deleteFolder = async ({ folderId, folderName, folderParent }) => {
    try {
      isDeletingFolder.value = true;

      const folderIdsToDelete = getFolderIdsToDelete(folderId);

      const excludeDeleted = (folder) => {
        const isTarget = folder.id === folderId;
        const included = folderIdsToDelete.includes(folder.id);
        return !isTarget && !included;
      };

      const newFolders = harbourStore.myFolders.filter(excludeDeleted);
      const isCurrentDeleted = harbourStore.currentFolder === folderId;
      harbourStore.setFolders(newFolders);

      if (isCurrentDeleted) updateCurrentIfDeleted(folderParent);

      await filesystemApiService.removeFolder({
        folderId,
        folderName,
        folderIds: folderIdsToDelete,
      });
    } catch (err) {
      harbourStore.getFolders();

      Toast.open({
        duration: 2500,
        message: `Something went wrong...`,
        position: 'is-top',
        type: 'is-danger',
      });
      console.error(err);
    } finally {
      isDeletingFolder.value = false;
    }
  };

  const updateCurrentIfDeleted = (folderParent) => {
    const special = ['#shared'];
    const containsSpecial = special.includes(folderParent);

    let newCurrent = folderParent;
    if (containsSpecial) newCurrent = '#home';
    harbourStore.setCurrentFolder(newCurrent);
    refreshGridRows(newCurrent);
  };

  const handleDownloadFolderCsv = (folder) => {
    const folderId = folder.id;
    const folderName = folder.name;

    const specialFolders = ['#shared'];
    const containsSpecial = (folderId) => specialFolders.includes(folderId);

    if (!folderId) return;
    if (containsSpecial(folderId)) return;

    downloadFolderAllCsv({ folderId, folderName });
  };

  const downloadFolderAllCsv = async ({ folderId, folderName }) => {
    try {
      const configJson = { allfolderitems: true };
      const respData = await filesystemApiService.getFolderAssets({ folderId, configJson });
      const folderAssets = respData.folderassets;

      if (!folderAssets.length) {
        Toast.open({
          duration: 2500,
          message: `I am afraid this folder contains no items to be CSVed. Please add items and try again.`,
          position: 'is-top',
          type: 'is-warning',
        });
        return;
      }

      downloadFolderCsv({ folderAssetsIds: [], folderId, folderName });
    } catch (err) {
      console.error(err);
    }
  };

  const downloadFolderCsv = async ({ folderAssetsIds, folderId, folderName }) => {
    const toast = Toast.open({
      duration: 8000000,
      message: `Starting preparing your CSV report now...`,
      position: 'is-bottom',
      type: 'is-black',
    });

    try {
      const blob = await filesystemApiService.getAssetDownloadCsv({
        assetDisplayIds: folderAssetsIds,
        folderId,
      });

      const fileName = `Harbour_export_${folderName}_${getFormattedTodayDate()}.csv`;
      downloadCsv(blob, fileName);
    } catch (err) {
      Toast.open({
        duration: 2500,
        message: `Unable to create csv download at this time. Please refresh and try again later or contact support@harbourshare.com`,
        position: 'is-top',
        type: 'is-warning',
      });
      console.error(err);
    } finally {
      toast.close();
    }
  };

  const downloadCsv = (blob, fileName) => {
    const data = new Blob([blob], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  };

  const getFormattedTodayDate = () => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const today = new Date();
    const yyyy = today.getFullYear();
    const month = monthNames[today.getMonth()];
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    let dateNowFormatted = month + '-' + dd + '-' + yyyy;
    return dateNowFormatted;
  };

  const handleDownloadFolderZip = (folder) => {
    const folderId = folder.id;
    const folderName = folder.name;
    const specialFolders = ['#shared'];
    const containsSpecial = (folderName) => specialFolders.includes(folderName);

    if (!folderName) return;
    if (containsSpecial(folderName)) return;

    downloadFolderAllZip({ folderId, folderName });
  };

  const downloadFolderAllZip = async ({ folderId, folderName }) => {
    try {
      const configJson = { allfolderitems: true };
      const respData = await filesystemApiService.getFolderAssets({ folderId, configJson });
      const folderAssets = respData.folderassets;

      if (!folderAssets.length) {
        Toast.open({
          duration: 2500,
          message: `I am afraid this folder contains no items to be downloaded. Please add items and try again.`,
          position: 'is-top',
          type: 'is-warning',
        });
        return;
      }

      const folderAssetsIds = folderAssets.map((asset) => asset.assetdisplay_displayid);
      const folderAttachments = [];
      folderAssets.forEach((asset) => {
        if (asset.attachments) folderAttachments.push(...asset.attachments);
      });

      downloadFolderZip({ folderAssetsIds, folderName, folderAttachments});
    } catch (err) {
      console.error(err);
    }
  };

  const downloadFolderZip = async ({ folderAssetsIds, folderName, folderAttachments = [], includeAssets = true, includeAttachments = true}) => {
    let totalDownloadCount = 0;
    if (includeAssets) totalDownloadCount += folderAssetsIds.length;
    if (includeAttachments) totalDownloadCount += folderAttachments.length;
    const toast = Toast.open({
      duration: 8000000,
      message: `Starting your download (${totalDownloadCount}) now...`,
      position: 'is-bottom',
      type: 'is-black',
    });
    isDownloadingFolder.value = true;

    try {
      const payload = {
        assetDisplayIds: folderAssetsIds,
        rootFolderName: folderName,
        includeAssets,
        includeAttachments,
      }
      const respData = await filesystemApiService.getAssetDownloadZip(payload);
      const downloadUrl = respData.zipdownloadsignedurl;
      window.open(downloadUrl);
    } catch (err) {
      Toast.open({
        duration: 2500,
        message: `Unable to create download at this time. Please refresh and try again later or contact support@harbourshare.com`,
        position: 'is-top',
        type: 'is-warning',
      });
      console.error(err);
    } finally {
      toast.close();
      isDownloadingFolder.value = false;
    }
  };

  const handleDuplicateFolder = (folder) => {
    if (!folder) {
      return;
    }

    const promptDuplicateFolder = (folder) => {
      Modal.open({
        component: HrbrDuplicateFolderModal,
        hasModalCard: true,
        props: {
          id: folder.id,
          defaultName: `Copy of ${folder.name}`,
        },
      });
    };

    promptDuplicateFolder(folder);
  };

  const handleShareFolder = (folder) => {
    if (!folder) return;
    const folderId = folder.id;
    const folderOrigin = folder.origin;
    const collaborators = folder.collaborators;

    const isEditPermission = isPermissionAvailable(EDIT_PERMISSION);
    const isEditSharedPermission = isEditSharedPermissionAvailable(folderOrigin, collaborators);

    const specialFolders = ['#home', '#shared'];
    const containsSpecial = (folderId) => specialFolders.includes(folderId);

    if (!folderId) return;
    if (!isEditPermission) return;
    if (!isEditSharedPermission) return;
    if (containsSpecial(folderId)) return;

    promptShareFolder(folder);
  };

  const promptShareFolder = (folder) => {
    Modal.open({
      component: HrbrEditFolderCollaboratorsModal,
      hasModalCard: true,
      props: {
        currentfolderid: folder.id,
        currentfoldername: folder.name,
        allFoldersArray: harbourStore.myFolders,
        emailInputData: emailInputData,
      },
    });
  };

  const isCurrentlyDownloading = computed(() => {
    return isDownloadingFolder.value;
  });

  onMounted(() => {
    loadCachedContacts();
  });

  return {
    addFolder,
    handleEditFolderName,
    handleDuplicateFolder,
    handleShareFolder,
    handleAddFolder,
    handleAddFolderMain,
    handleDeleteFolder,
    handleDownloadFolderCsv,
    handleDownloadFolderZip,
    getParentFolderPermissions,
    expandNodesToTarget,
    downloadFolderZip,
    refreshGridRows,
    isPermissionAvailable,
    isDownloadingFolder,
    isEditSharedPermissionAvailable,
    isCurrentlyDownloading,
  };
}
