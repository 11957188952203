<script setup>
import filesystemApiService from '@/services/filesystem-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import {
ToastProgrammatic as Toast,
} from 'buefy';
import { computed, nextTick, ref } from 'vue';
import { useFolderActions } from '../Sidebar/composables/use-folder-actions';

const harbourStore = useHarbourStore();
const folderActions = useFolderActions();

const props = defineProps({
    id: String,
    defaultName: String
});

const folderName = ref(props.defaultName);
const isDuplicating = ref(false);

const cancelButton = {
  text: 'Cancel',
  styleType: 'secondary',
  style: {
    marginRight: '6px',
    width: '90px',
  },
};

const duplicateButton = computed(() => {
  return {
    text: 'Save',
    styleType: 'primary',
    style: {
      width: '90px',
    },
    disabled: isDuplicating.value,
    loading: isDuplicating.value,
    dataTestId: 'duplicate-folder-button',
  };
});

const emit = defineEmits(['close']);
const closeModal = () => emit('close');

const onCancelClick = () => {
  closeModal();
};

const onSaveClick = async () => {
  await duplicateFolder({ folderId: props.id, newFolderName: folderName.value });
  closeModal();
};

const duplicateFolder = async ({folderId, newFolderName}) => {
  isDuplicating.value = true;

    try {
      const prevCurrentFolder = harbourStore.currentFolder;
      const response = await filesystemApiService.duplicateFolder({ folderId, newFolderName });
      const newFolderId = response.id;

      await harbourStore.refreshFolders();

      harbourStore.setCurrentFolder(newFolderId);
      harbourStore.updateFoldersBeingCopied(newFolderId);
      folderActions.refreshGridRows(prevCurrentFolder, folderId, newFolderId);
      nextTick(() => folderActions.expandNodesToTarget(newFolderId));

      Toast.open({
        message: 'Folder is being duplicated...',
        type: 'is-success',
        position: 'is-top',
        duration: 2500,
      });
    } catch (err) {
      Toast.open({
        message: `Something went wrong...`,
        position: 'is-top',
        type: 'is-danger',
        duration: 2500,
      });
    } finally {
      isDuplicating.value = false;
    }
  };

</script>

<template>
    <div class="hrbr-duplicate-folder-modal modal-card">
        <header class="modal-card-head">
        <p class="modal-card-title">New folder name</p>
        </header>
        <section class="modal-card-body">
        <div class="hrbr-duplicate-folder-modal__description">
            Please type the folder name
        </div>
        <div class="hrbr-duplicate-folder-modal__fields">
            <b-input v-model.trim="folderName" placeholder="Name" icon-pack="fal" :icon="'pen'">
            </b-input>
        </div>
        </section>
        <footer class="modal-card-foot">
          <HrbrButton :button="cancelButton" @click="onCancelClick" />
          <HrbrButton :button="duplicateButton" @click="onSaveClick" />
        </footer>
    </div>
</template>

<style lang="postcss" scoped>
.hrbr-duplicate-folder-modal {
  &__description {
    margin-bottom: 10px;
  }

  &__button {
    width: 75px;

    &:disabled {
      background-color: var(--main-primary-color)
    }
  }
}

.fa-spin {
  animation-duration: 0.5s;
}

</style>
